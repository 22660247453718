import React from "react";
import DATA from "../DATA_CONST";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
// import { thumbsUp } from "@fortawesome/free-solid-svg-icons";
import icon_1 from "../assets/icon_1.png";
import icon_2 from "../assets/icon_2.png";
import icon_3 from "../assets/icon_3.png";
const HomePage = () => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="yeo-client">
        <div className="container yeo-client-list" id="introduce">
          <div className="columns">
            <div className="column col-12">
              {/* <h3 className="feature-title">
                {t('PARTNER.TITLE')}
                </h3> */}
              <div className="client-logo">
                <a href="#" target="_blank">
                  <img src="./images/technology.png" alt="" />
                </a>
                <a href="#" target="_blank">
                  <img src="./images/ickeck.png" alt="" />
                </a>
                <a href="#" target="_blank">
                  <img src="./images/pal_vietnam.png" alt="" />
                </a>
                <a href="#" target="_blank">
                  <img src="./images/asia_traver.png" alt="" />
                </a>
                <a href="#" target="_blank">
                  <img src="./images/evotek.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Client */}

      {/* introduce */}

      <div className="">
        <div class="container-test">
          <div class="item-test">
            <p className="introduce-title">2019</p>
            <p className="">{t("INTRODUCE.INTRODUCE_TITLE_1")}</p>
            <span>{t("INTRODUCE.INTRODUCE_DESC_1")}</span>
          </div>
          <div class="item-test">
            <p className="introduce-title ">30+</p>{" "}
            <p className="">{t("INTRODUCE.INTRODUCE_TITLE_2")}</p>
            <span>{t("INTRODUCE.INTRODUCE_DESC_2")}</span>
          </div>
          <div class="item-test">
            <p className="introduce-title">32+</p>{" "}
            <p className="">{t("INTRODUCE.INTRODUCE_TITLE_3")}</p>
            <span id="service">{t("INTRODUCE.INTRODUCE_DESC_3")}</span>
          </div>
        </div>
      </div>
      {/* service */}
      <div className="service-containers">
        <h2 className="yeo-do-title">{t("SERVICE.SERVICE_TILE")}</h2>
        <div class="service-items">
          <div class=" service-contents">
            <img className="icon-service" src={icon_1} alt="" />
            <h5>Interface Design</h5>
            <p>{t("SERVICE.SERVICE_TILE_1")}</p>
          </div>
          <div class=" service-contents">
            <img className="icon-service" src={icon_3} alt="" />
            <h5>Blockchain Development</h5>
            <p>{t("SERVICE.SERVICE_TILE_2")}</p>
          </div>
          <div class="  service-contents">
            <img className="icon-service" src={icon_2} alt="" />
            <h5>Software Development</h5>
            <p id="solution">{t("SERVICE.SERVICE_TILE_3")}</p>
          </div>
        </div>
      </div>
      {/* SOLUTION*/}
      <div className="yeo-dos">
        <h2 className="yeo-do-title">{t("SOLUTION.SOLUTION_TITLE")}</h2>
        <div className="yeo-do-items" id="we-do">
          <div className=" yeo-do-item ">
            {/* <div className="yeo-do-title-1">
              <span className="cms"> C2i </span>
              <span className="cms">-</span>
              <span className="cms-title"> CMS</span>
            </div> */}
            <div className="yeo-do-desc solution-item-1">
              <div className="d-flex solution-item">
                <i className="fa-solid fa-thumbs-up"></i>
                <span> {t("SOLUTION.SOLUTION_DESC_5")}</span>
              </div>
              <div className="d-flex solution-item">
                <i className="fa-solid fa-tower-cell"></i>
                <span> {t("SOLUTION.SOLUTION_DESC_2")}</span>
              </div>
              <div className="d-flex solution-item">
                <i className="fa-solid fa-users"></i>
                <span> {t("SOLUTION.SOLUTION_DESC_6")}</span>
              </div>
              {/* <div className="d-flex solution-item">
                <i className="fa-solid fa-thumbs-up"></i>
                <span>{t("SOLUTION.SOLUTION_DESC_7")}</span>
              </div> */}
            </div>
          </div>
          <div className="image-main yeo-do-item">
            {" "}
            <img src="./images/main-removebg-preview.png" alt="" />
          </div>
          <div className="yeo-do-item  solution-item-2">
            {/* <div className="yeo-do-title-2">
              <span className="crm"> C2i </span>
              <span> - </span>
              <span> CRM</span>
            </div> */}
            <div className="yeo-do-desc ">
              <div className="d-flex solution-item">
                <span> {t("SOLUTION.SOLUTION_DESC_3")}</span>
                <i className="fa-solid fa-chart-simple"></i>
              </div>
              <div className="d-flex solution-item">
                <span> {t("SOLUTION.SOLUTION_DESC_4")}</span>
                <i className="fa-solid fa-city"></i>
              </div>
              <div className="d-flex solution-item">
                <span>{t("SOLUTION.SOLUTION_DESC_1")}</span>
                <i className="fa-brands fa-fedora"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Price */}

      {/* Team */}
      <div className="yeo-team" id="team">
        <div className="container yeo-body">
          <div className="columns">
            <div className="column col-12">
              <h2 className="yeo-do-title">{t("TEAM.TITLE")}</h2>
            </div>
            {DATA.map((item, index) => {
              const translate = item.position;
              const skill1 = item.skill1;
              const skill2 = item.skill2;
              const skill3 = item.skill3;

              return (
                <div
                  key={index}
                  className="image_manager column col-4 col-lg-6 col-sm-12"
                >
                  <div className="image_container">
                    <div className="mask mask_type1 mask_type1-a1">
                      <img
                        src={item.image}
                        className="mask__img"
                        alt="avatar"
                      />
                    </div>
                    <div
                      style={{
                        height: "60px",
                      }}
                    >
                      <span className="image_title">{item.name}</span>
                      <span className="image_subtitle">
                        {t(`TEAM.${translate}`)}
                      </span>
                      <div className="information">
                        <div className=" information1">
                          <p>{t(`TEAM.${skill1}`)}</p>
                        </div>
                        <div className=" information2">
                          <p>{t(`TEAM.${skill2}`)}</p>
                        </div>
                        <div className=" information3">
                          <p>{t(`TEAM.${skill3}`)}</p>
                        </div>
                        <div className="icon-team">
                          {" "}
                          <span
                            onClick={() => window.open(item.linkedIn, "_blank")}
                            className="iconify"
                            data-icon="logos:linkedin-icon"
                          ></span>
                          <span
                            onClick={() => window.open(item.telegram, "_blank")}
                            className="iconify"
                            data-icon="logos:telegram"
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* Team */}
    </div>
  );
};
export default HomePage;
