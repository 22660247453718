export default [
  {
    image: "./images/team6.jpg",
    name: "Hung Do",
    position: "GAME_DEVELOPMENT",
    linkedIn: "#",
    telegram: "#",
    skill1: "TEAM_DESC_3",
    skill2: "TEAM_DESC_4",
    skill3: "TEAM_DESC_5",
  },
  {
    image: "./images/team1.jpg",
    name: "Tony",
    position: "CEO_CO-FOUNDEN",
    linkedIn: "#",
    telegram: "#",
    skill1: "TEAM_DESC_1",
    skill2: "TEAM_DESC_2",
    skill3: "TEAM_DESC_9",
  },

  {
    image: "./images/team3.jpg",
    name: "Minh Nguyen",
    position: "BLOCKCHIAN_DEVELOPMENT",
    linkedIn: "#",
    telegram: "#",
    skill1: "TEAM_DESC_6",
    skill2: "TEAM_DESC_7",
    skill3: "TEAM_DESC_8",
  },
  // {
  //   "image": "./images/team4.jpg",
  //   "name": "Mai",
  //   "position": "ARTIST",
  //   "linkedIn": "#",
  //   "telegram": "#"
  // },
  // {
  //   "image": "./images/team5.jpg",
  //   "name": "Huy Tung",
  //   "position": "APPLICATION_DEVOLOPMENT",
  //   "linkedIn": "#",
  //   "telegram": "#"
  // },
  // {
  //   "image": "./images/team6.jpg",
  //   "name": "Minh Duc",
  //   "position": "QC",
  //   "linkedIn": "#",
  //   "telegram": "#"
  // }
];
