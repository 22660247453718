import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { set, useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import "../css/modal.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const Header = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (language) => {
    console.log(language);
    i18n.changeLanguage(language);
  };
  const onclick = () => {
    //  e.styte.display="flex"
    document.querySelector(".popup").style.display = "flex";
  };
  const close = () => {
    //  e.styte.display="flex"
    document.querySelector(".popup").style.display = "none";
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const submit = (data) => {
    console.log(data);
    toast.success(
      "Thank you for your contacting us, we will response to you right now"
    );
  };
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      {" "}
      <div className="yeo-slogan">
        <section id="nav">
          {" "}
          <a
            className="btn btn-link "
            href="#"
            onClick={() => {
              document.getElementById("nav").classList.remove("active");
            }}
          >
            {t("NAVIGATION.MENU_ITEM_1")}
          </a>
          <a
            className="btn btn-link "
            href="/#introduce"
            onClick={() => {
              document.getElementById("nav").classList.remove("active");
            }}
          >
            {t("NAVIGATION.MENU_ITEM_3")}
          </a>
          <a
            className="btn btn-link"
            href="/#solution"
            onClick={() => {
              document.getElementById("nav").classList.remove("active");
            }}
          >
            {t("NAVIGATION.MENU_ITEM_2")}
          </a>
          <a
            className="btn btn-link "
            href="#contact"
            onClick={() => {
              document.getElementById("nav").classList.remove("active");
            }}
          >
            {t("NAVIGATION.MENU_ITEM_4")}
          </a>
          <a
            className="btn btn-link "
            href="/#"
            onClick={() => {
              document.getElementById("nav").classList.remove("active");
            }}
          >
            {t("NAVIGATION.MENU_ITEM_5")}
          </a>
        </section>
        {/* popup */}
        <div className="popup">
          <ToastContainer />
          <div className="popup_content">
            <a onClick={close}>
              <i className="close fa-solid fa-circle-xmark"></i>
            </a>
            <h2 className="title">Contacting</h2>
            <form className="form_popup" onSubmit={handleSubmit(submit)}>
              <label>Name</label>
              <input
                type="text"
                {...register("name", { required: true, minLength: 3 })}
              />
              {errors.name && errors.name.type === "required" && (
                <div className="errors">
                  {t("MESSAGE_ERROR.ERRORS_REQUIRED")}
                </div>
              )}
              {errors.name && errors.name.type === "minLength" && (
                <div className="errors">{t("MESSAGE_ERROR.ERROS_PATTERN")}</div>
              )}
              <label>Email</label>
              <input
                type="email"
                {...register("email", {
                  required: true,
                  pattern: { value: /^\S+@\S+$/i },
                })}
              />
              {errors.email && errors.email.type === "required" && (
                <div className="errors">
                  {t("MESSAGE_ERROR.ERRORS_REQUIRED")}
                </div>
              )}
              {errors.email && errors.email.type === "pattern" && (
                <div className="errors">{t("MESSAGE_ERROR.ERROS_PATTERN")}</div>
              )}
              <label>Mobile</label>
              <input
                type="text"
                {...register("mobile", {
                  required: true,
                  pattern: { value: /^[0-9+-]+$/ },
                  minLength: 6,
                  maxLength: 12,
                })}
              />
              {errors.mobile && errors.mobile.type === "required" && (
                <div className="errors">
                  {t("MESSAGE_ERROR.ERRORS_REQUIRED")}
                </div>
              )}
              {errors.mobile && errors.mobile.type === "pattern" && (
                <div className="errors">{t("MESSAGE_ERROR.ERROS_PATTERN")}</div>
              )}
              {errors.mobile && errors.mobile.type === "minLength" && (
                <div className="errors">{t("MESSAGE_ERROR.ERROS_PATTERN")}</div>
              )}
              {errors.mobile && errors.mobile.type === "maxLength" && (
                <div className="errors">{t("MESSAGE_ERROR.ERROS_PATTERN")}</div>
              )}
              <button className="submit">Submit</button>
            </form>
          </div>
        </div>

        {/* menu */}
        <div className="container yeo-header menu-banner" id="nav-fixed">
          <div className="columns">
            <div className="column col-12">
              <header className="navbar ">
                <section>
                  <div
                    onClick={() => {
                      if (
                        document
                          .getElementById("nav")
                          .classList.contains("active")
                      ) {
                        document
                          .getElementById("nav")
                          .classList.remove("active");
                      } else {
                        document.getElementById("nav").classList.add("active");
                      }
                    }}
                  >
                    <i className="fa-solid fa-bars"></i>
                  </div>
                </section>
                <section className="navbar-section">
                  <a className="navbar-brand logo" href="./">
                    <img className="logo-img" src="/images/logo.png" alt="" />
                  </a>
                </section>

                <section className="navbar-section ">
                  <a className="btn btn-link hide-sm" href="#">
                    {t("NAVIGATION.MENU_ITEM_1")}
                  </a>
                  <a className="btn btn-link hide-sm" href="/#service">
                    {t("NAVIGATION.MENU_ITEM_3")}
                  </a>
                  <a className="btn btn-link hide-sm" href="/#solution">
                    {t("NAVIGATION.MENU_ITEM_2")}
                  </a>

                  <a className="btn btn-link hide-sm" href="#contact">
                    {t("NAVIGATION.MENU_ITEM_4")}
                  </a>
                  <a
                    className="btn btn-link hide-sm hide-sm"
                    href="/#introduce"
                  >
                    {t("NAVIGATION.MENU_ITEM_5")}
                  </a>
                  {/* <a className="btn btn-primary btn-hire-me" href="#">
                    {t("NAVIGATION.DOWNLOAD")}
                  </a> */}
                  <div className="language btn btn-primary btn-hire-me">
                    <FontAwesomeIcon className="icon_language" icon={faGlobe} />
                    <select
                      className="select_language"
                      onChange={(e) => changeLanguage(e.target.value)}
                    >
                      <option value="en">English</option>
                      <option value="vi">Vietnamese</option>
                      <option value="jp">Japanese</option>
                    </select>
                  </div>
                </section>
              </header>
            </div>
          </div>
        </div>
      </div>
      {/* banner */}
      <div className="yeo-slide">
        <Slider {...settings}>
          {/* banner 1 */}
          <div className="container slogan slick-bg-1 cus_banner">
            <div className="columns">
              <div className="column col-7 col-sm-12">
                <div className="slogan-content">
                  <p className="title_short">{t("BANNERS.TITLE_1")}</p>
                  <h1>
                    <span className="slogan-bold">{t("BANNER.TITLE_1")}</span>{" "}
                    <br />
                    <span className="slogan-bold">{t("BANNER.TITLE_2")}</span>
                    <span className="slogan-bold">{t("BANNER.TITLE_3")}</span>
                  </h1>
                  <p className="bss">{t("BANNER.DESCRIPTION")}</p>
                  {/* <a
                    className="btn btn-primary btn-lg btn-start"
                    onClick={onclick}
                    target="_blank"
                  >
                    {t("BANNER.START")}
                  </a> */}
                </div>
              </div>
              <div className="column col-5 hide-sm">
                {/* <img className="slogan-img" src={imgBanner2} alt="banner2" /> */}
              </div>
            </div>
          </div>

          {/* banner 2 */}
          <div className="container slogan slick-bg-2 cus_banner">
            <div className="columns">
              <div className="column col-7 col-sm-12">
                <div className="slogan-content">
                  <p className="title_short">
                    {" "}
                    <p>{t("BANNERS.TITLE_2")}</p>
                  </p>
                  <h1>
                    <span className="slogan-bold">{t("BANNER2.TITLE_1")}</span>{" "}
                    <br />
                    <span className="slogan-bold">{t("BANNER2.TITLE_2")}</span>
                    <span className="slogan-bold">{t("BANNER2.TITLE_3")}</span>
                  </h1>
                  <p className="ass">{t("BANNER2.DESCRIPTION")}</p>
                  {/* <a
                    className="btn btn-primary btn-lg btn-start"
                    onClick={onclick}
                    target="_blank"
                  >
                    {t("BANNER.START")}
                  </a> */}
                </div>
              </div>
              <div className="column col-5 hide-sm">
                {/* <img className="slogan-img" src={imgBanner2} alt="banner2" /> */}
              </div>
            </div>
          </div>

          {/* banner 3 */}
          <div className="container slogan slick-bg-3 cus_banner">
            <div className="columns">
              <div className="column col-7 col-sm-12">
                <div className="slogan-content">
                  <p className="title_short">
                    {" "}
                    <p className="title_short">{t("BANNERS.TITLE_3")}</p>
                  </p>
                  <h1>
                    <span className="slogan-bold">{t("BANNER3.TITLE_1")}</span>{" "}
                    <br />
                    <span className="slogan-bold">{t("BANNER3.TITLE_2")}</span>
                    <span className="slogan-bold">{t("BANNER3.TITLE_3")}</span>
                  </h1>
                  <p className="css">{t("BANNER3.DESCRIPTION")}</p>
                  {/* <a
                    className="btn btn-primary btn-lg btn-start"
                    onClick={onclick}
                    target="_blank"
                  >
                    {t("BANNER.START")}
                  </a> */}
                </div>
              </div>
              <div className="column col-5 hide-sm">
                {/* <img className="slogan-img" src={imgBanner2} alt="banner2" /> */}
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default Header;
