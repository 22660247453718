import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import "./../node_modules/react-slick/dist/react-slick";
import { I18nextProvider } from "react-i18next";
import i18n from "./translation/i18n";
import { BrowserRouter } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import reportWebVitals from './reportWebVitals';
window.onscroll = () => {
  const navbar = document.getElementById("nav-fixed");
  if (window.scrollY > 100) {
    navbar.classList.add("nav-active");
    navbar.classList.remove("container");
  } else {
    navbar.classList.remove("nav-active");
    navbar.classList.add("container");
  }
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
