import Footer from "../component/Footer";
import Header from "../component/Header";
import { Outlet } from "react-router-dom";
function WebsiteLayout(chilrdren) {
    return ( 
<div>
<Header/>
<Outlet/>
<Footer/>
</div>
     );
}

export default WebsiteLayout;